import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

interface Props {
  data: JSX.Element[];
}

// The bootstrap Carousel can't work because of my custom CSS, so I write a custom Carousel for my need
const Carousel = ({ data }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentData = () => {
    return data ? (
      data.map((d, index) => {
        return (
          <div
            key={index}
            className={`fade carousel-item ${
              currentIndex === index
                ? "carousel-item-display"
                : "carousel-item-hidden "
            }`}
          >
            {" "}
            {d}
          </div>
        );
      })
    ) : (
      <p>"Loading..."</p>
    );
  };

  const dots = () => {
    return (
      data &&
      data.map((d, index) => {
        return (
          <span
            key={index}
            className={`dot ${index === currentIndex && "dot-active"}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        );
      })
    );
  };

  return (
    <div className="carousel-container">
      <div className="row carousel-row">
        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 carousel-col">
          {currentIndex > 0 && (
            <FontAwesomeIcon
              className="carousel-chevron"
              icon={faChevronLeft}
              onClick={() => setCurrentIndex(currentIndex - 1)}
            />
          )}
        </div>
        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
          {currentData()}
        </div>
        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 carousel-col">
          {currentIndex < data.length - 1 && (
            <FontAwesomeIcon
              className="carousel-chevron"
              icon={faChevronRight}
              onClick={() => setCurrentIndex(currentIndex + 1)}
            />
          )}
        </div>
      </div>

      <div className="row carousel-dots">{dots()}</div>
    </div>
  );
};

export default Carousel;
