import React from "react";
import Carousel from "./Carousel";

interface Props {
  data: WorkData[];
}

interface WorkData {
  id: number;
  title: string;
  company: string;
  year: string;
  description: string[];
  stacks?: string[];
  detailed: boolean;
  projects?: ProjectData[];
}

interface ProjectData {
  task: string;
  highlight: string;
  stacks?: string[];
}

const Work = ({ data }: Props) => {
  const workTitle = (company: string, title: string, year: string) => {
    return (
      <>
        <h3>{title}</h3>
        <p>
          {year} at {company}
        </p>
      </>
    );
  };

  const workStacks = (stacks?: string[]) => {
    return (
      stacks && (
        <div className="stacks">
          <h4>Stacks</h4>
          <p>{stacks.join(", ")}</p>
        </div>
      )
    );
  };

  const workDescription = (descriptions?: string[]) => {
    return (
      <div className="description">
        <h4>Description</h4>
        {descriptions &&
          descriptions.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
      </div>
    );
  };

  const workProject = (projects?: ProjectData[]) => {
    const a = projects ? parseInt(`${12 / projects.length}`): 12;

    return (
      projects && (
        <div className="projects">
          <h4>Selected Projects</h4>
          <div className="row">
            {projects.map((item: ProjectData) => (
              <div
                className={`col-xs-12 col-sm-${a} col-md-${a} col-lg-${a}`}
                key={item.task}
              >
                <h5>{item.task}</h5>
                <p>{item.highlight}</p>
                {workStacks(item.stacks)}
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  const detailed = (d: WorkData) => {
    return (
      <>
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            {workTitle(d.company, d.title, d.year)}
            {workDescription(d.description)}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-sm-12">{workProject(d.projects)}</div>
        </div>
      </>
    );
  };

  const brief = (d: WorkData) => {
    return (
      <div className="row">
        <div className="col-sm-8 col-sm-offset-2">
          {workTitle(d.company, d.title, d.year)}
          {workDescription(d.description)}
          {workStacks(d.stacks)}
        </div>
      </div>
    );
  };

  const workData = () => {
    return (
      data &&
      data.map((d) => {
        return d.detailed ? detailed(d) : brief(d);
      })
    );
  };

  return (
    <div id="work" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Work Experience</h2>
          </div>
        </div>
        <Carousel data={workData()} />
      </div>
    </div>
  );
};

export default Work;
