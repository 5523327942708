import React from "react";

interface Props {
  playerBehaviorFields: PlayerConfigData;
  inputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface PlayerConfigData {
  initialCapital: number;
  maxConsecutiveLoss: number;
  maxPlay: number;
  maxGainFactor: number;
  maxLossFactor: number;
}

const PlayerConfig = ({
  playerBehaviorFields,
  inputHandler,
  selectHandler,
}: Props) => {
  return (
    <>
      <h4>Player's Configuration</h4>
      <div className="mb-3">
        <label
          htmlFor={"playerBehaviorFields.initialCapital"}
          className="form-label"
        >
          Initial Capital:
        </label>
        <input
          type="number"
          min={1}
          className="form-control"
          required

          id="playerBehaviorFields.initialCapital"
          name="playerBehaviorFields.initialCapital"
          value={playerBehaviorFields.initialCapital || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor={"playerBehaviorFields.maxConsecutiveLoss"}
          className="form-label"
        >
          Max Acceptable Consecutive Loss:
        </label>
        <input
          type="number"
          min={1}
          required

          className="form-control"
          id="playerBehaviorFields.maxConsecutiveLoss"
          name="playerBehaviorFields.maxConsecutiveLoss"
          value={playerBehaviorFields.maxConsecutiveLoss || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label htmlFor={"playerBehaviorFields.maxPlay"} className="form-label">
          Max Play:
        </label>
        <input
          type="number"
          min={1}
          className="form-control"
          required

          id="playerBehaviorFields.maxPlay"
          name="playerBehaviorFields.maxPlay"
          value={playerBehaviorFields.maxPlay || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor={"playerBehaviorFields.maxGainFactor"}
          className="form-label"
        >
          Max Gain Factor:
        </label>
        <input
          type="number"
          min={0}
          className="form-control"
          id="playerBehaviorFields.maxGainFactor"
          required

          name="playerBehaviorFields.maxGainFactor"
          value={playerBehaviorFields.maxGainFactor || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor={"playerBehaviorFields.maxLossFactor"}
          className="form-label"
        >
          Max Loss Factor:
        </label>
        <input
          type="number"
          min={0}
          max={1}
          step={0.01}
          required

          className="form-control"
          id="playerBehaviorFields.maxLossFactor"
          name="playerBehaviorFields.maxLossFactor"
          value={playerBehaviorFields.maxLossFactor || ""}
          onChange={inputHandler}
        />
      </div>
    </>
  );
};

export default PlayerConfig;
