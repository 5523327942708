import React, { useRef, useState } from "react";
import SlotConfig, { ProbabilityField } from "./SlotConfig";
import PlayerConfig from "./PlayerConfig";
import FurtherConfig from "./FurtherConfig";
import SpinPokerDefault from "../../data/spin-poker.json";
import LeftNavMenu from "./LeftNavMenu";
import SimulationResult, { PayoutCalculationResult } from "./SimulationResult";

/**
 * This is the main class of Spin Poker demo
 * it composes of 2 parts: 1. Configurations; 2. Result
 *
 * For Configurations:
 *  i.    Slot Config
 *  ii.   Distribution Config
 *  iii.  Player Config
 *  iv.   Further Config
 *  v.    Probabilities Array
 *
 *
 *
 */
const GameDemoSpinPoker = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL!;
  const apiKey = process.env.REACT_APP_BACKEND_API_KEY!;
  const googleColabSpinPokerUrl = process.env.REACT_APP_GOOGLE_COLAB_SPIN_POKER;

  const formRef = useRef<HTMLFormElement>(null);

  const [slotConfigFields, setSlotConfigFields] = useState(
    SpinPokerDefault.slotConfig
  );

  const [distributionFields, setDistributionFields] = useState(
    SpinPokerDefault.distributionConfig
  );

  const [playerBehaviorFields, setPlayerBehaviorFields] = useState(
    SpinPokerDefault.playerBehavior
  );

  const [furtherConfigFields, setFurtherConfigFields] = useState(
    SpinPokerDefault.furtherConfig
  );

  const [probabilityArray, setProbabilityArray] = useState<ProbabilityField[]>(
    SpinPokerDefault.probabilityArray
  );

  const [currentNavItem, setCurrentNavItem] = useState("slot_config");

  const [calculationResult, setCalculationResult] =
    useState<PayoutCalculationResult[]>();

  const [submitBtnText, setSubmitBtnText] = useState("Submit");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    handleChange(name, value);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    handleChange(name, value);
  };

  /*
    in this class, we should assume that every fields have exactly 1 period "."
    and this period separating the object-name, and object-field-name
  */
  const handleChange = (name: string, value: string) => {
    const fieldPath = name.split(".");
    if (fieldPath.length === 2) {
      switch (fieldPath[0]) {
        case "slotConfigFields":
          setSlotConfigFields((values) => ({
            ...values,
            [fieldPath[1]]: value,
          }));
          break;
        case "playerBehaviorFields":
          setPlayerBehaviorFields((values) => ({
            ...values,
            [fieldPath[1]]: value,
          }));
          break;
        case "distributionFields":
          setDistributionFields((values) => ({
            ...values,
            [fieldPath[1]]: value,
          }));
          break;
        case "furtherConfigFields":
          setFurtherConfigFields((values) => ({
            ...values,
            [fieldPath[1]]: value,
          }));
      }
      // window.alert(JSON.stringify(slotConfigFields));
    }
  };

  const displayContent = () => {
    return (
      <>
        <div
          className={currentNavItem === "slot_config" ? "visible" : "invisible"}
        >
          <SlotConfig
            slotConfigFields={slotConfigFields}
            distributionFields={distributionFields}
            probabilityArray={probabilityArray}
            inputHandler={handleInputChange}
            selectHandler={handleSelectChange}
            setProbabilityArray={setProbabilityArray}
          />
        </div>
        <div
          className={
            currentNavItem === "player_behavior" ? "visible" : "invisible"
          }
        >
          <PlayerConfig
            playerBehaviorFields={playerBehaviorFields}
            inputHandler={handleInputChange}
            selectHandler={handleSelectChange}
          />
        </div>
        <div
          className={
            currentNavItem === "further_config" ? "visible" : "invisible"
          }
        >
          <FurtherConfig
            furtherConfigFields={furtherConfigFields}
            inputHandler={handleInputChange}
            selectHandler={handleSelectChange}
          />
        </div>
      </>
    );
  };

  /**
   * Submit event
   */
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(apiUrl);

    setCalculationResult([]);
    setSubmitBtnText("Loading ...");

    let requestBody = {
      slotConfig: JSON.parse(JSON.stringify(slotConfigFields)),
      playerBehavior: playerBehaviorFields,
      furtherConfig: furtherConfigFields,
    };

    requestBody.slotConfig["distribution"] = distributionFields;
    requestBody.slotConfig["probabilities"] = probabilityArray;
    console.log(JSON.stringify(requestBody));
    fetchData(requestBody).then((data) => {
      // alert(`within fetchData 132: ${JSON.stringify(data)}`);
      if (data.length > 0) {
        setCalculationResult(data);
      }
    });
  };

  const fetchData = async (requestBody: any) => {
    try {
      const url = `${apiUrl}/game/payout/optimize?simSize=${requestBody.furtherConfig.simSize}&top=${requestBody.furtherConfig.top}&simPerAllocation=${requestBody.furtherConfig.simPerAllocation}`;
      const data = requestBody;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-API-KEY": apiKey,
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not OK");
      }
      const responseData = await response.json();

      setSubmitBtnText("Submit");

      return responseData;
    } catch (error) {
      alert(`${error}`);

      setSubmitBtnText("Submit");
    }
  };

  return (
    <div id="project-demo" className="text-center">
      <div className="container">
        <h3>Game Payout Allocation Optimization</h3>
        <div className="description">
          <p>
            This project is a showcase for Game Mathematician role, that try to
            solve the problem of payout allocation that, under specific
            gambler's behavior, the payout allocation can maximize the expected
            gain and high average length of game
          </p>
          <p>
            All default values are coming from the result of my Google Colab
            project, however, the overall methodology can be treated as the
            general framework of finding optimal payout allocaiton.
          </p>
          <p>
            For detail rationale and mathematical background, please see my{" "}
            <a href={googleColabSpinPokerUrl} target="_blank">
              Google Colab
            </a>
          </p>
        </div>

        <form onSubmit={handleSubmit} ref={formRef}>
          <div className="row">
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
              {
                <LeftNavMenu
                  currentNavItem={currentNavItem}
                  setCurrentNavItem={setCurrentNavItem}
                />
              }
            </div>

            <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
              <div className="row">{displayContent()}</div>
            </div>

            <div className="row row-reverse">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={submitBtnText !== "Submit"}
              >
                {submitBtnText}
              </button>
            </div>
          </div>
        </form>

        {/* this part will be refactored to support more than 1 results */}
        {calculationResult &&
          calculationResult.length > 0 &&
          calculationResult[0] && (
            <SimulationResult
              averageGameLength={calculationResult[0].averageGameLength}
              payoutAllocation={calculationResult[0].payoutAllocation}
              averageFinalCapitals={calculationResult[0].averageFinalCapitals}
            />
          )}
      </div>
    </div>
  );
};

export default GameDemoSpinPoker;
