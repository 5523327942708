import React from "react";

interface PayoutCalculationResult {
  averageGameLength: number;
  payoutAllocation: PayoutAllocation;
  averageFinalCapitals: number;
}

interface PayoutAllocation {
  optimizedValue: number;
  patternPayoutAllocation: PatternPayoutAllocation[];
  coefficientArray?: number[];
}

interface PatternPayoutAllocation {
  name: string;
  prob: number;
  payout: number;
  payoutProportion: number;
}

const SimulationResult = ({
  averageGameLength,
  payoutAllocation,
  averageFinalCapitals,
}: PayoutCalculationResult) => {
  return (
    <>
      <div>
        <h3>Simulation Result</h3>
      </div>
      <hr />
      <div className="row">
        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <p>Average Game Length</p>
        </div>
        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <p>{averageGameLength}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <p>Average Final Balance</p>
        </div>
        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <p>{averageFinalCapitals}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <p>Optimized Payout</p>
        </div>
        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <p>{payoutAllocation.optimizedValue}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <p>Pattern Payout allocation</p>
        </div>
        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <table className="table">
            <thead>
              <tr>
                {/* <th>#</th> */}
                <th>Pattern Name</th>
                <th>Probability</th>
                <th>Payout</th>
                <th>Payout Proportion</th>
              </tr>
            </thead>
            <tbody>
              {payoutAllocation.patternPayoutAllocation.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.prob}</td>
                    <td>{item.payout}</td>
                    <td>{item.payoutProportion}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* {payoutAllocation.coefficientArray && (
        <div className="row">
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <p>Coefficient Array</p>
          </div>
          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
            <p>{JSON.stringify(payoutAllocation.coefficientArray)}</p>
          </div>
        </div>
      )} */}
    </>
  );
};

export type { PayoutCalculationResult };
export default SimulationResult;
