
import Carousel from "./Carousel";

interface Props {
  data: EduData[];
}

interface EduData {
  id: number;
  title: string;
  year: string;
  program: string;
  image: string;
  highlight: string[];
  desc: string[];
  subjects?: string[];
  skills?: string[];
  extra?: string[];
}

const Education = ({ data }: Props) => {
  const eduData = () => {
    return (
      data &&
      data.map((d, index) => {
        return (
          <>
            <div className="row">
              {" "}
              <h3>{d.title}</h3>
              <h5>
                {d.program} ({d.year})
              </h5>
            </div>
            <br/>
            <div className="row">
              <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                <div className="">
                  <img src={d.image} className="img-responsive" alt="" />{" "}
                </div>
              </div>

              <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                {d.desc && (
                  <div className="description">
                    {/* <h4>Description</h4> */}

                    {d.desc.map((item, index) => (
                      <p key={index}>{item}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <br/>
            <div className="row">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <div className="highlight">
                  <h4>Highlights</h4>
                  <ul>
                    {d.highlight.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <div className="skill">
                  {d.skills && (
                    <div className="">
                      <h4>Skills</h4>
                      {d.skills && <p>{d.skills.join(", ")}</p>}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <div className="extra">
                  {d.extra && (
                    <div className="">
                      <h4>Extra Curricular Activities</h4>
                      <ul>
                        {d.extra.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      })
    );
  };

  return (
    <div id="education" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Educational Background</h2>
          </div>
        </div>
        <Carousel data={eduData()} />
      </div>
    </div>
  );
};

export default Education;
