import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import SmoothScroll from "smooth-scroll";
import GameDemoSpinPokerPage from "./components/game_project/GameDemoSpinPokerPage";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project/demo">
          <Route path="spin-poker" element={<GameDemoSpinPokerPage />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
