import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import CustomImage from "./Image";

interface Props {
  data: CertificateData[];
}

interface CertificateData {
  id: number;
  name: string;
  issuedBy: string;
  // issuedDate: string;
}

const Certificates = ({ data }: Props) => {
  return (
    <div id="materials" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Certificates</h2>
          </div>
        </div>
        <div className="row">
          <p>
            Here are my professional certificates issued by well known companies
            / institutions. 
          </p>
          <p>I can also provide the work reference letter from my
          MCC Manager, and academic reference from my UoN personal tutor.</p>

          <div className="certificate-items">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Issued By</th>
                </tr>
              </thead>
              <tbody>
                {data ? (
                  data.map((d, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{d.name}</td>
                      <td>{d.issuedBy}</td>
                      {/* <td>{d.issuedDate}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
            {/* {data
              ? showAllItems(showAll, data).map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                  >
                    <CustomImage
                      title={d.title}
                      smallImage={d.smallImage}
                      priority={d.priority}
                      url={d.url}
                    />
                  </div>
                ))
              : "Loading..."} */}
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Certificates;
