import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ProbabilityField } from "./SlotConfig";

interface Props {
  probabilityArray: ProbabilityField[];
  setProbabilityArray: (probabilityArray: ProbabilityField[]) => void;
}

const PrababilityFieldArray = ({
  probabilityArray,
  setProbabilityArray,
}: Props) => {
  /**
   * Method to add item to the array at the end (equivalent to push method)
   */
  const addFieldItem = () => {
    setProbabilityArray([...probabilityArray, {}]);
  };

  /**
   * Method to delete item from the array at specific index
   */
  const deleteFieldItem = (index: number) => {
    setProbabilityArray([
      ...probabilityArray.slice(0, index),
      ...probabilityArray.slice(index + 1),
    ]);
  };

  /**
   * Handle input change event
   * will first check the input tag name.
   *  IF probabilityFieldName -> update the item name
   *  IF probabilityFieldProb -> update the item prob (when it can be parsed to float)
   */
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    const newList = [...probabilityArray];

    let searchField = newList[index];
    if (searchField) {
      switch (name) {
        case "probabilityFieldName":
          searchField.name = value;
          break;
        case "probabilityFieldProb":
          // if (!isNaN(parseFloat(value))) {
          //   searchField.prob = parseFloat(value);
          // } else {
          searchField.prob = value;
          // }

          break;
      }

      setProbabilityArray(newList);
    }
  };

  /**
   * handle blur event for prob input
   * directly update the input value to 0 if it is violated certain criterion:
   *  1. not a float
   *  2. less than 0
   *  3. greater than 1
   */
  const handleBlurChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    const newList = [...probabilityArray];

    let searchField = newList[index];
    if (searchField) {
      const parsedValue = parseFloat(value);
      /*
        only probability field needs to check
      */
      if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 1) {
        searchField.prob = 0;
        setProbabilityArray(newList);
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-10 col-sm-11 col-md-11 col-lg-11">
          <div className="prob-container">
            <div className="row">
              {probabilityArray.length === 0 ? (
                <p>The array is empty now, please click add items</p>
              ) : (
                probabilityArray.map((element: any, index) => {
                  return (
                    <div
                      className="col-xs-12 col-sm-6 col-md-6 col-lg-6"
                      key={index}
                    >
                      <div className="row">
                        <div className="input-group prob-item">
                          <div className="input-group-addon ">
                            <span className="input-group-text prob-item-label">
                              {index}
                            </span>
                          </div>

                          <input
                            className="form-control prob-item-input"
                            required
                            name="probabilityFieldName"
                            id="probabilityFieldName"
                            value={element.name}
                            placeholder="Name"
                            onChange={(event) =>
                              handleInputChange(event, index)
                            }
                          />
                          <input
                            className="form-control prob-item-input"
                            required
                            name="probabilityFieldProb"
                            id="probabilityFieldProb"
                            type="text"
                            value={element.prob}
                            placeholder="Probability"
                            onChange={(event) =>
                              handleInputChange(event, index)
                            }
                            onBlur={(event) => handleBlurChange(event, index)}
                          />

                          <button
                            className="btn btn-danger prob-item-button"
                            type="button"
                            onClick={() => deleteFieldItem(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
        <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1">
          <button
            type="button"
            className="btn btn-md btn-success"
            onClick={() => addFieldItem()}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    </>
  );
};

export default PrababilityFieldArray;
