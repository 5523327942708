import React from "react";

interface Props {
  furtherConfigFields: FurtherConfigData;
  inputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface FurtherConfigData {
  simSize: number;
  simPerAllocation: number;
  top: number;
}

const FurtherConfig = ({
  furtherConfigFields,
  inputHandler,
  selectHandler,
}: Props) => {
  return (
    <>
      <h4>General Configuration</h4>
      <p>As a demo, these parameters are limited to lower value for faster calculation. In my investigation, practically using sim-size=1000 and sim-per-solution=100 is adequate to get sensible result</p>
      <div className="mb-3">
        <label htmlFor={"furtherConfigFields.simSize"} className="form-label">
          Sim Size:
        </label>
        <input
          type="number"
          min={1}
          max={100}
          required
          className="form-control"
          name="furtherConfigFields.simSize"
          id="furtherConfigFields.simSize"
          value={furtherConfigFields.simSize || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor={"furtherConfigFields.simPerAllocation"}
          className="form-label"
        >
          Sim Size Per feasible solution:
        </label>
        <input
          type="number"
          min={1}
          max={50}
          required
          className="form-control"
          name="furtherConfigFields.simPerAllocation"
          id="furtherConfigFields.simPerAllocation"
          value={furtherConfigFields.simPerAllocation || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label htmlFor={"furtherConfigFields.top"} className="form-label">
          Top N Results:
        </label>
        <select
          name="furtherConfigFields.top"
          id="furtherConfigFields.top"
          className="form-control"
          required
          value={furtherConfigFields.top}
          onChange={selectHandler}
        >
          <option value="1">1</option>
          {/* <option value="10">10</option> */}
        </select>
      </div>
    </>
  );
};

export default FurtherConfig;
