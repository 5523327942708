import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";

interface Props {
  data: ContactData[];
}

interface ContactData {
  id: number;
  name: string;
  type: string;
  value: string;
}

const getIcon = (contact: ContactData) => {
  switch (contact.type) {
    case "phone":
      return <FontAwesomeIcon icon={faPhone} />;
    case "email":
      return <FontAwesomeIcon icon={faEnvelope} />;
    case "website":
      if (contact.name.toLowerCase() === "linkedin") {
        return <FontAwesomeIcon icon={faLinkedin} />;
      } else if (contact.name.toLowerCase() === "github") {
        return <FontAwesomeIcon icon={faGithub} />;
      }
  }
  return <></>;
};

const Contact = (props: Props) => {
  return (
    <div id="contact" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Contact Info</h2>
          </div>
        </div>

        <div className="row">
          {props.data.map((contact, index) => (
            <div key={contact.id}>
              <h4>
                {getIcon(contact)} {contact.name}
              </h4>
              {contact.type === "website" ? (
                <a href={contact.value} target="_blank" className="awhite">
                  <p>{contact.value}</p>
                </a>
              ) : (
                <p>{contact.value}</p>
              )}{" "}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contact;
