import React from "react";
// import GameProject from "./GameProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDice,
  faLineChart,
  faPieChart,
} from "@fortawesome/free-solid-svg-icons";

interface Props {
  data: ProjectData[];
}

interface ProjectData {
  id: number;
  icon: string;
  title: string;
  desc: string;
  content: ProjectContent[];
}

interface ProjectContent {
  id: number;
  title: string;
  website: string;
}

const getIcon = (icon: string) => {
  switch (icon) {
    case "fa-chart-line":
      return <FontAwesomeIcon icon={faLineChart} />;
    case "fa-dice":
      return <FontAwesomeIcon icon={faDice} />;
  }
  return <></>;
};

export const Project = ({ data }: Props) => {
  return (
    <div id="projects" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Personal Projects</h2>
          </div>
        </div>
        <div className="row">
          <p>
            This section contains my custom projects demonstrating my
            statistical knowledge and competence.
          </p>
          <p>
            In addition, to demonstrate my programming experience, this personal
            website is developed using React, and hosted in AWS
          </p>
        </div>
        <div className="row content">
          {data
            ? data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-12 col-md-6">
                  <div className="icon"> {getIcon(d.icon)} </div>
                  <h3>{d.title}</h3>
                  <p>{d.desc}</p>
                  <ul>
                    {d.content &&
                      d.content.map((item, index) => {
                        return (
                          <li key={index}>
                            -{" "}
                            <a
                              href={item.website}
                              target={
                                item.website.startsWith("/") ? "" : "_blank"
                              }
                            >
                              {item.title}
                              <br />
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
