import React from "react";
import GameDemoSpinPoker from "./GameDemoSpinPoker";
import OtherRouteNavigationBar from "../OtherRouteNavigationBar";
import Contact from "../Contact";

import ContactJsonData from "../../data/contact.json";

const GameDemoSpinPokerPage = () => {
  return (
    <>
      <OtherRouteNavigationBar />
      <GameDemoSpinPoker />
      <Contact data={ContactJsonData} />
    </>
  );
};

export default GameDemoSpinPokerPage;
