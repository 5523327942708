import React from "react";

interface Props {
  distributionFields: DistributionConfigData;
  inputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface DistributionConfigData {
  distributionType: string;
  min?: number;
  max?: number;
  alpha?: number;
  beta?: number;
  shape?: number;
  rate?: number;
  scale?: number;
}

const distributionExtraParameters = (
  distributionFields: DistributionConfigData,
  inputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
) => {
  switch (distributionFields.distributionType) {
    case "INV_UNIFORM":
      return <div></div>;
    case "UNIFORM":
      return (
        <>
          <div className="mb-3">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <label htmlFor={"distributionFields.min"} className="form-label">
                Min:
              </label>

              <input
                type="number"
                min={0}
                required
                className="form-control"
                name="distributionFields.min"
                id="distributionFields.min"
                value={distributionFields.min || ""}
                onChange={inputHandler}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <label htmlFor={"distributionFields.max"} className="form-label">
                Max:
              </label>

              <input
                type="number"
                min={0}
                max={10000}
                required
                className="form-control"
                name="distributionFields.max"
                id="distributionFields.max"
                value={distributionFields.max || ""}
                onChange={inputHandler}
              />
            </div>
          </div>
        </>
      );
    case "BETA":
      return (
        <>
          <div className="mb-3">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <label
                htmlFor={"distributionFields.alpha"}
                className="form-label"
              >
                Alpha:
              </label>

              <input
                type="number"
                min={0}
                required
                className="form-control"
                name="distributionFields.alpha"
                id="distributionFields.alpha"
                value={distributionFields.alpha || ""}
                onChange={inputHandler}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <label htmlFor={"distributionFields.beta"} className="form-label">
                Beta:
              </label>

              <input
                type="number"
                min={0}
                required
                className="form-control"
                name="distributionFields.beta"
                id="distributionFields.beta"
                value={distributionFields.beta || ""}
                onChange={inputHandler}
              />
            </div>
          </div>
        </>
      );
    default:
  }
};

const DistributionField = ({
  distributionFields,
  inputHandler,
  selectHandler,
}: Props) => {
  return (
    <>
      <select
        value={distributionFields.distributionType}
        id="distributionFields.distributionType"
        className="form-control"
        name="distributionFields.distributionType"
        onChange={selectHandler}
      >
        <option value="INV_UNIFORM">Inverse Uniform Distribution</option>
        <option value="UNIFORM">Uniform Distribution</option>
        <option value="BETA">Beta Distribution</option>
      </select>
      {distributionExtraParameters(distributionFields, inputHandler)}
    </>
  );
};

export type { DistributionConfigData };
export default DistributionField;
