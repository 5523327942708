import React, { useState } from "react";

interface Props {
  currentNavItem: string;
  setCurrentNavItem: (navItem: string) => void;
}

const LeftNavMenu = ({ currentNavItem, setCurrentNavItem }: Props) => {
  return (
    <div className="gameConfigNavContainer">
      <ul className="nav flex-column">
        <li className="nav-item">
          <a
            className={
              "nav-link pointer " +
              (currentNavItem === "slot_config" && "active")
            }
            onClick={() => {
              setCurrentNavItem("slot_config");
            }}
          >
            Slot Configuration
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              "nav-link pointer " +
              (currentNavItem === "player_behavior" && "active")
            }
            onClick={() => setCurrentNavItem("player_behavior")}
          >
            Player's Behavior
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              "nav-link pointer " +
              (currentNavItem === "further_config" && "active")
            }
            onClick={() => setCurrentNavItem("further_config")}
          >
            Further Configuration
          </a>
        </li>
      </ul>
    </div>
  );
};

export default LeftNavMenu;
