import React, { useState } from "react";
import DistributionField, { DistributionConfigData } from "./DistributionField";
import PrababilityFieldArray from "./PrababilityFieldArray";

interface Props {
  slotConfigFields: SlotConfigData;
  distributionFields: DistributionConfigData;
  probabilityArray: ProbabilityField[];
  inputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  setProbabilityArray: (probabilityArray: ProbabilityField[]) => void;
}

interface SlotConfigData {
  cost: number;
  expGainUpperBound: number;
  payoutLowerBound: number;
  payoutUpperBound: number;
  constraintType: string;
}

interface ProbabilityField {
  // index: number;
  name?: string;
  prob?: number | string;
}

const SlotConfig = ({
  slotConfigFields,
  distributionFields,
  probabilityArray,
  inputHandler,
  selectHandler,
  setProbabilityArray,
}: Props) => {
  return (
    <>
      <h4>Slot Configuration</h4>
      <div className="mb-3">
        <label htmlFor={"slotConfigFields.cost"} className="form-label">
          Cost:
        </label>
        <input
          type="number"
          min={1}
          className="form-control"
          name="slotConfigFields.cost"
          required
          id="slotConfigFields.cost"
          value={slotConfigFields.cost || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor={"slotConfigFields.expGainUpperBound"}
          className="form-label"
        >
          Expected Gain (Payout-cost) Upper Bound:
        </label>
        <input
          type="number"
          className="form-control"
          max={0}
          required
          step={0.01}
          id="slotConfigFields.expGainUpperBound"
          name="slotConfigFields.expGainUpperBound"
          value={slotConfigFields.expGainUpperBound || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor={"slotConfigFields.payoutLowerBound"}
          className="form-label"
        >
          Payout Lower Bound:
        </label>
        <input
          type="number"
          min={1}
          className="form-control"
          id="payoutLowerBound"
          required
          name="slotConfigFields.payoutLowerBound"
          value={slotConfigFields.payoutLowerBound || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor={"slotConfigFields.payoutUpperBound"}
          className="form-label"
        >
          Payout Upper Bound:
        </label>
        <input
          type="number"
          min={1}
          className="form-control"
          id="payoutLowerBound"
          required
          name="slotConfigFields.payoutUpperBound"
          value={slotConfigFields.payoutUpperBound || ""}
          onChange={inputHandler}
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor={"slotConfigFields.constraintType"}
          className="form-label"
        >
          Constraint Type:
        </label>
        <select
          name="slotConfigFields.constraintType"
          id="slotConfigFields.constraintType"
          className="form-control"
          required
          value={slotConfigFields.constraintType}
          onChange={selectHandler}
        >
          <option value="DETERMINISTIC">Deterministic</option>
          <option value="STOCHASTIC">Stochastic</option>
          <option value="MIXED" disabled>
            Mixed
          </option>
        </select>
      </div>
      <div className="mb-3">
        <label
          htmlFor={"slotConfigFields.distributionType"}
          className="form-label"
        >
          Distribution Type:
        </label>
        <DistributionField
          distributionFields={distributionFields}
          inputHandler={inputHandler}
          selectHandler={selectHandler}
        />
      </div>

      <div className="mb-3">
        <label
          htmlFor={"slotConfigFields.distributionType"}
          className="form-label"
        >
          Probabilities with Pattern name. <br />
          <span>
            Required to have at least 2 items, and total probability at most =
            1, no need to supply 0 payout event items
          </span>
        </label>
        <PrababilityFieldArray
          probabilityArray={probabilityArray}
          setProbabilityArray={setProbabilityArray}
        />
      </div>
    </>
  );
};

export type { ProbabilityField };
export default SlotConfig;
