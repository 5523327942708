import React from "react";

interface Props {
  data: Paragraph[];
}

interface Paragraph {
  id: number;
  content: string;
}

const Summary = (props: Props) => {
  return (
    <div id="summary">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            {" "}
            <img src="img/about.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="summary-text">
              <h2>Summary</h2>
              <div>
                {props.data &&
                  props.data.map((paragraph, index) => <p key={index}>{paragraph.content}</p>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
