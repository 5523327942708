import React from "react";
import Contact from "./Contact";
import Education from "./Education";
import Certificates from "./Certificates";
import { Project } from "./Project";
import Summary from "./Summary";
import Work from "./Work";

import SummaryJsonData from "../data/summary.json";
import ProjectData from "../data/projects.json";
import ContactJsonData from "../data/contact.json";
import WorkJsonData from "../data/work.json";
import EducationJsonData from "../data/edu.json";
import CertificateJsonData from "../data/certificate.json";
import SmoothScroll from "smooth-scroll";
import NavigationBar from "./NavigationBar";

const Home = () => {
  return (
    <>
      <NavigationBar />
      <Summary data={SummaryJsonData} />
      <Work data={WorkJsonData} />
      <Education data={EducationJsonData} />
      <Project data={ProjectData} />
      <Certificates data={CertificateJsonData} />
      <Contact data={ContactJsonData} />
    </>
  );
};

export default Home;
