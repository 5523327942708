import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * This Nav bar class is for the page other than the default one (i.e. base url other than "/")
 * When clicking on any nav item, it will move to the main route ("/#XXXXXX") target div by id
 */

const OtherRouteNavigationBar = () => {
  const navigate = useNavigate(); // From react-router v6

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            David K.L., Hui
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                className="nav-route-item page-scroll"
                onClick={() => {
                  navigate("/#summary", { state: { targetId: "summary" } });
                }}
              >
                Summary
              </a>
            </li>
            <li>
              <a
                className="nav-route-item page-scroll"
                onClick={() => {
                  navigate("/#work", { state: { targetId: "work" } });
                }}
              >
                Work Experience
              </a>
            </li>
            <li>
              <a
                className="nav-route-item page-scroll"
                onClick={() => {
                  navigate("/#education", { state: { targetId: "education" } });
                }}
              >
                Education
              </a>
            </li>
            <li>
              <a
                className="nav-route-item page-scroll"
                onClick={() => {
                  navigate("/#projects", { state: { targetId: "projects" } });
                }}
              >
                Projects
              </a>
            </li>

            <li>
              <a
                className="nav-route-item page-scroll"
                onClick={() => {
                  navigate("/#materials", { state: { targetId: "materials" } });
                }}
              >
                Materials
              </a>
            </li>

            <li>
              <a
                className="nav-route-item page-scroll"
                onClick={() => {
                  navigate("/#contact", { state: { targetId: "contact" } });
                }}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default OtherRouteNavigationBar;
